import { Client, Content } from '@prismicio/client';
import urlJoin from 'url-join';
import i18nConfig, { LOCALES } from '@/i18nConfig';
import { Lang } from '@/types/locales';
import { getCountryFromLocale } from '@/app/api/utils';

import { Locale } from './types';

export const getLocales = async (
  doc: Content.AllDocumentTypes,
  client: Client<Content.AllDocumentTypes>,
): Promise<Locale[]> => {
  const [repository, altDocs] = await Promise.all([
    client.getRepository(),
    doc.alternate_languages.length > 0
      ? client.getAllByIDs(
          doc.alternate_languages.map((altLang) => altLang.id),
          {
            lang: '*',
            // Exclude all fields to speed up the query.
            fetch: `${doc.type}.__nonexistent-field__`,
          },
        )
      : Promise.resolve([]),
  ]);

  return [doc, ...altDocs]
    .filter((item) => {
      return checkIfLangIsEnabled(item.lang);
    })
    .map((page) => {
      const lang = repository?.languages.find((l) => l.id === page.lang);

      return {
        lang: (lang?.id || LOCALES.EN_CA) as Lang,
        url: page?.url || '',
        lang_name: lang?.name || '',
      };
    });
};

export const getCountryCodeFromLang = (lang: Lang): string => {
  return lang.split('-')[1].toUpperCase();
};

export const addLocale = (
  path: string,
  locale: string,
  defaultLocale = i18nConfig.defaultLocale,
) => {
  if (locale === defaultLocale) {
    return path;
  }

  const lowerCasePath = path.toLowerCase();
  const lowerCaseLocale = locale.toLowerCase();

  if (
    lowerCasePath.startsWith(lowerCaseLocale) ||
    lowerCasePath.startsWith(`/${lowerCaseLocale}`)
  ) {
    return path;
  }

  return urlJoin('/', locale, path);
};

export const getLocationName = (lang: Lang) => getCountryFromLocale(lang);

export const checkIfLangIsEnabled = (lang: string) => Object.values(LOCALES).includes(lang);

export const isLang = (value: string): value is Lang => Object.values(LOCALES).includes(value);
